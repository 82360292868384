window.addEventListener('load', () => {
  $('#session_email, #session_password').on('change', () => {
    $('.login-container').removeClass('login_error');
  });

  $('.anyErrorClose').on('click', () => {
    $('.anyErrorClose').parent().removeClass('d-block');
  });

  $('#forgetPass').on('click', (ev) => {
    $(ev.target).parents('.flip-container').addClass('flip');
    $('#forgetSec').show();
  });

  $('#ssoLogin').on('click', (ev) => {
    $(ev.target).parents('.flip-container').addClass('flip');
    $('#ssoLoginSec').show();
  });

  $('#resendBtn').on('click', (ev) => {
    $(ev.target).parents('.flip-container').addClass('flip');
    $('#resendSec').show();
  });

  $('.back-to-login').on('click', (ev) => {
    $(ev.target).parents('.flip-container').removeClass('flip');
    $('#resendSec').hide();
    $('#forgetSec').hide();
    $('#ssoLoginSec').hide();
  });

  $('.show-pwd.pwd-icon').on('click', () => {
    $('#password').attr('type', 'text');
    $('.show-pwd.pwd-icon').addClass('hide');
    $('.hide-pwd.pwd-icon').removeClass('hide');
  });

  $('.hide-pwd.pwd-icon').on('click', () => {
    $('#password').attr('type', 'password');
    $('.show-pwd.pwd-icon').removeClass('hide');
    $('.hide-pwd.pwd-icon').addClass('hide');
  });
});
